import React from 'react';
import { useTranslation } from 'react-i18next';

function Testimony() {
  const { t } = useTranslation();

  return (
    <section className="tw-bg-[#225AB50D]">
      <div className="tw-mx-auto tw-container tw-py-20 xl:tw-py-28 tw-px-[30px] xl:tw-px-64">
        <div className="tw-bg-white tw-py-[50px] xl:tw-py-10 tw-px-[20px] xl:tw-px-24 tw-text-center" style={{ boxShadow: '0px 10px 20px 0px #D3D3D32E' }}>
          <h3 className="tw-text-[26px] tw-text-leclercPrimary tw-font-normal">
            <span className="tw-font-semibold">
              {t('LANDING_LECLERC.TESTIMONY.TEXT_A')}
            </span>
            &nbsp;
            <span className="tw-font-normal">
              {t('LANDING_LECLERC.TESTIMONY.TEXT_B')}
            </span>
            <span className="tw-font-semibold">
              {t('LANDING_LECLERC.TESTIMONY.TEXT_C')}
            </span>
            &nbsp;
            <span className="tw-font-normal">
              {t('LANDING_LECLERC.TESTIMONY.TEXT_D')}
            </span>
          </h3>
          <div className="tw-mt-10">
            <div className="tw-text-leclercOrange tw-text-sm tw-font-bold">
              {t('LANDING_LECLERC.TESTIMONY.TEXT_E')}
            </div>
            <div className="tw-text-leclercYellow tw-text-xs">
              {t('LANDING_LECLERC.TESTIMONY.TEXT_F')}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimony;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import { useMediaQuery } from 'react-responsive';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config';

import sbtiLogo from '../../../../assets/ui/SBTI.svg';

import getUserLocal from '../../../../utils/getUserLocal';
import getClimateAlignementInfos from '../../../../utils/getClimateAlignementInfos';
import getHexColorWithOpacity from '../../../../utils/getHexColorWithOpacity';
import getCompleteColorByScore from '../../../../utils/getCompleteColorByScore';

function ClimateCommitments({ profile }) {
  const [climateObjectivesData, setClimateObjectivesData] = useState();
  const [isPublicEngagement, setIsPublicEngagement] = useState(false);
  const [isSbtiTarget, setIsSbtiTarget] = useState();
  const [scope12Color, setScope12Color] = useState();
  const [scope3Color, setScope3Color] = useState();

  const fullConfig = resolveConfig(tailwindConfig);
  const isDesktop = useMediaQuery({
    query: `(min-width: ${fullConfig.theme.screens.lg})`,
  });

  const { get, response } = useFetch(process.env.REACT_APP_PARTNER_API);

  const { t } = useTranslation();

  // BLOC RECUPERARTION DES DONNEES
  useEffect(() => {
    const getClimateObjectives = async () => {
      try {
        const data = await get(`/profiles/${profile.id}/climate-objectives`);

        if (response.ok) {
          setClimateObjectivesData(data);
          if (data) {
            if (
              data.target_sbt === 5 &&
              ((data.obj_scope_1_2?.direct_final_year &&
                data.obj_scope_1_2?.direct_initial_year &&
                data.obj_scope_1_2?.percent_reduction_goal) ||
                (data.obj_scope_3_n_1?.complete_final_year &&
                  data.obj_scope_3_n_1?.complete_initial_year &&
                  data.obj_scope_3_n_1?.percent_reduction_target))
            ) {
              setIsSbtiTarget(6);
            } else {
              setIsSbtiTarget(data.target_sbt === null ? 5 : data.target_sbt);
            }
            setScope12Color(fullConfig.theme.colors[getCompleteColorByScore(data.obj_scope_1_2?.manual_degree_position)]);
            setScope3Color(fullConfig.theme.colors[getCompleteColorByScore(data.obj_scope_3_n_1?.manual_degree_position)]);
          }
          setIsPublicEngagement(
            (data?.obj_scope_1_2 &&
              data?.obj_scope_1_2?.percent_reduction_goal !== null &&
              data?.obj_scope_1_2?.direct_initial_year !== null &&
              data?.obj_scope_1_2?.direct_final_year !== null) ||
              (data?.obj_scope_3_n_1 &&
                data?.obj_scope_3_n_1?.percent_reduction_target !== null &&
                data?.obj_scope_3_n_1?.complete_initial_year !== null &&
                data?.obj_scope_3_n_1?.complete_final_year !== null),
          );
        }
        return null;
      } catch (error) {
        throw error;
      }
    };

    getClimateObjectives();
  }, [profile]);

  // BLOC AFFICHAGE DONNEES ET COULEURS
  const getSbtiInfos = (level) => {
    switch (level) {
      case 1:
        return { text: `${t('CLIMATE_ACTIONS.COMMITMENT')} 1.5°C` };
      case 2:
        return { text: `${t('CLIMATE_ACTIONS.COMMITMENT')} WB2°C` };
      case 3:
        return { text: `${t('CLIMATE_ACTIONS.COMMITMENT')} 2°C` };
      case 4:
        return { text: `${t('CLIMATE_ACTIONS.COMMITMENT_ONGOING')}` };
      case 5:
        return { text: `${t('CLIMATE_ACTIONS.NO_COMMITMENT')}` };
      case 6:
        return { text: `${t('CLIMATE_ACTIONS.COMMITMENT_NOT_CERTIFIED')}` };
      default:
        return { text: `${t('CLIMATE_ACTIONS.NO_COMMITMENT')}` };
    }
  };

  const unitText = {
    1: 'IN_ABSOLUTE_VALUE',
    2: 'PER_VOLUME',
    3: 'PER_EUROS',
  };

  // Si le profile est une PME, ne pas afficher le bloc
  if (profile.type === 2) {
    return null;
  }

  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-py-6 tw-px-6 lg:tw-px-7 tw-mb-4 tw-bg-white tw-rounded tw-border tw-border-solid tw-border-greySix">
      <div className=" tw-text-base tw-text-greyOne tw-font-moskauGrotesk tw-font-semibold tw-mb-4">
        {t('CLIMATE_ACTIONS.CLIMATE_COMMITMENTS')}
      </div>
      <div
        className={`tw-w-full tw-flex ${
          isDesktop ? 'tw-gap-x-4' : 'tw-flex-col tw-gap-y-4'
        } tw-items-stretch tw-mb-4`}
      >
        <div
          className={`${
            climateObjectivesData && isDesktop ? 'tw-w-3/5' : 'tw-w-full'
          } tw-flex tw-items-center tw-gap-x-4 tw-bg-backgroundCleanBlue tw-p-4 tw-rounded-sm tw-border tw-border-solid tw-border-greySix`}
        >
          <div className=" tw-text-sm tw-text-blueDark tw-font-medium tw-leading-[21px]">
            {isPublicEngagement
              ? `${t('CLIMATE_ACTIONS.PUBLIC_COMMITMENT')} `
              : `${t('CLIMATE_ACTIONS.NO_PUBLIC_COMMITMENT')} `}
            {t('CLIMATE_ACTIONS.CARBON_FOOTPRINT_REDUCING')}
          </div>
        </div>
        {isSbtiTarget && (
          <div
            className={`${
              isDesktop ? 'tw-w-2/5' : 'tw-w-full'
            } tw-flex tw-items-center tw-gap-x-4  tw-p-4 tw-bg-backgroundCleanBlue tw-rounded-sm tw-border tw-border-solid tw-border-greySix`}
          >
            <img
              src={sbtiLogo}
              alt="sbti logo"
              width="34"
              height="auto"
              className=" tw-rounded-full"
            />
            <div className=" tw-text-sm tw-text-greyDarkHeavy">
              <div className=" tw-text-sm tw-text-greyDarkHeavy tw-font-medium tw-leading-[21px]">
                {getSbtiInfos(isSbtiTarget).text}
                {isSbtiTarget === 1 ||
                isSbtiTarget === 2 ||
                isSbtiTarget === 3 ? (
                  <span className=" tw-text-xs tw-font-normal tw-leading-[18px]">
                    <br />
                    {t('CLIMATE_ACTIONS.SBTI_CERTIFIED')}
                  </span>
                  ) : (
                    ''
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
      {isDesktop && (
        <div className="tw-w-full tw-flex tw-items-center tw-border tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-greySix">
          <div className="tw-w-2/5" />
          <div className="tw-w-3/5 tw-flex tw-items-center tw-text-xs tw-text-greyFour tw-leading-[18px] tw-py-3">
            <div className="tw-w-1/2 tw-text-center">
              {t('CLIMATE_ACTIONS.TARGET_REDUCTION')}
            </div>
            <div className="tw-w-1/2 tw-text-center">
              {t('CLIMATE_ACTIONS.CLIMATE_ALIGNEMENT')}
            </div>
          </div>
        </div>
      )}
      {isDesktop ? (
        <div className="tw-w-full tw-flex tw-items-center tw-py-3 tw-border tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-greySix">
          <div className="tw-w-2/5 tw-flex tw-items-center tw-gap-x-2">
            <div key={scope12Color} className={`tw-w-4 tw-h-4 tw-min-h-4 tw-min-w-4 tw-self-center tw-bg-${climateObjectivesData?.obj_scope_1_2?.percent_reduction_goal ? getClimateAlignementInfos(climateObjectivesData?.obj_scope_1_2.manual_degree_position).bgColor : 'notePasDengagement'} tw-rounded-full`} style={{ border: `4px solid ${getHexColorWithOpacity(scope12Color, 0.1)}`, backgroundClip: 'padding-box' }} />
            <div className=" tw-text-base tw-text-primaryNewBlue tw-font-moskauGrotesk tw-font-semibold">
              {t('CLIMATE_ACTIONS.SCOPE_1_&_2')}
            </div>
          </div>
          <div className="tw-w-3/5 tw-flex tw-items-center">
            <div className="tw-w-1/2 tw-flex tw-flex-col tw-items-center">
              {climateObjectivesData?.obj_scope_1_2?.percent_reduction_goal ? (
                <>
                  <div className=" tw-font-moskauGrotesk tw-font-semibold tw-text-lg">
                    {
                      climateObjectivesData?.obj_scope_1_2
                        .percent_reduction_goal
                    }
                    %
                  </div>
                  <div className="tw-text-xs tw-text-greyTwo">
                    {t(unitText[climateObjectivesData?.obj_scope_1_2?.direct_value_type])}
                  </div>
                  <div className="tw-text-xs tw-text-greyTwo">
                    {t('CLIMATE_ACTIONS.BETWEEN')}{' '}
                    {climateObjectivesData?.obj_scope_1_2.direct_initial_year}{' '}
                    et {climateObjectivesData?.obj_scope_1_2.direct_final_year}
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
            <div className="tw-w-1/2 tw-flex tw-flex-col tw-items-center">
              {climateObjectivesData?.obj_scope_1_2?.percent_reduction_goal ? (
                <div
                  className={`tw-w-24 tw-text-sm tw-font-medium tw-text-${
                    getClimateAlignementInfos(
                      climateObjectivesData?.obj_scope_1_2
                        .manual_degree_position,
                    ).textColor
                  } tw-text-center tw-py-1 tw-bg-${
                    getClimateAlignementInfos(
                      climateObjectivesData?.obj_scope_1_2
                        .manual_degree_position,
                    ).bgColor
                  }/10 tw-rounded-2xl tw-border-${
                    getClimateAlignementInfos(
                      climateObjectivesData?.obj_scope_1_2
                        .manual_degree_position,
                    ).textColor
                  } tw-border tw-border-solid`}
                >
                  {
                    getClimateAlignementInfos(
                      climateObjectivesData?.obj_scope_1_2
                        .manual_degree_position,
                    ).text
                  }
                </div>
              ) : (
                <>
                  <div className="tw-text-xs tw-text-greyThree tw-font-moskauGrotesk tw-font-normal">
                    {t('CLIMATE_ACTIONS.NO_ALIGNEMENT')}
                  </div>
                  <div className="tw-text-xs tw-text-notePasDengagement tw-font-moskauGrotesk tw-font-semibold tw-mt-1">
                    {t('CLIMATE_ACTIONS.NO_COMMITMENT_2')}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="tw-w-full tw-flex tw-flex-col tw-gap-y-6 tw-border tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-greySix tw-py-6">
          <div className="tw-w-full tw-flex tw-items-center tw-gap-x-2">
            <div key={scope12Color} className={`tw-w-4 tw-h-4 tw-min-h-4 tw-min-w-4 tw-self-center tw-bg-${climateObjectivesData?.obj_scope_1_2?.percent_reduction_goal ? getClimateAlignementInfos(climateObjectivesData?.obj_scope_1_2.manual_degree_position).bgColor : 'notePasDengagement'} tw-rounded-full`} style={{ border: `4px solid ${getHexColorWithOpacity(scope12Color, 0.1)}`, backgroundClip: 'padding-box' }} />
            <div className=" tw-text-base tw-text-primaryNewBlue tw-font-moskauGrotesk tw-font-semibold">
              {t('CLIMATE_ACTIONS.SCOPE_1_&_2')}
            </div>
          </div>
          <div className="tw-w-full tw-flex tw-items-center">
            <div className="tw-w-1/2 tw-text-xs tw-text-greyFour tw-leading-[18px]">
              {t('CLIMATE_ACTIONS.TARGET_REDUCTION')}
            </div>
            <div className="tw-w-1/2 tw-flex tw-flex-col tw-items-center">
              {climateObjectivesData?.obj_scope_1_2?.percent_reduction_goal ? (
                <>
                  <div className="tw-font-moskauGrotesk tw-font-semibold tw-text-lg">
                    {
                      climateObjectivesData?.obj_scope_1_2
                        .percent_reduction_goal
                    }
                    %
                  </div>
                  <div className="tw-text-xs tw-text-greyTwo">
                    {t(unitText[climateObjectivesData?.obj_scope_1_2?.direct_value_type])}
                  </div>
                  <div className="tw-text-xs tw-text-greyTwo">
                    {t('CLIMATE_ACTIONS.BETWEEN')}{' '}
                    {climateObjectivesData?.obj_scope_1_2.direct_initial_year}{' '}
                    et {climateObjectivesData?.obj_scope_1_2.direct_final_year}
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="tw-w-full tw-flex tw-items-center">
            <div className="tw-w-1/2 tw-text-xs tw-text-greyFour tw-leading-[18px]">
              {t('CLIMATE_ACTIONS.CLIMATE_ALIGNEMENT')}
            </div>
            <div className="tw-w-1/2 tw-flex tw-flex-col tw-items-center">
              {climateObjectivesData?.obj_scope_1_2?.percent_reduction_goal ? (
                <div
                  className={`tw-w-24 tw-text-sm tw-font-medium tw-text-${
                    getClimateAlignementInfos(
                      climateObjectivesData?.obj_scope_1_2
                        .manual_degree_position,
                    ).textColor
                  } tw-text-center tw-py-1 tw-bg-${
                    getClimateAlignementInfos(
                      climateObjectivesData?.obj_scope_1_2
                        .manual_degree_position,
                    ).bgColor
                  }/10 tw-rounded-2xl tw-border-${
                    getClimateAlignementInfos(
                      climateObjectivesData?.obj_scope_1_2
                        .manual_degree_position,
                    ).textColor
                  } 
                     tw-border tw-border-solid
                     `}
                >
                  {
                    getClimateAlignementInfos(
                      climateObjectivesData?.obj_scope_1_2
                        .manual_degree_position,
                    ).text
                  }
                </div>
              ) : (
                <>
                  <div className="tw-text-xs tw-text-greyThree tw-font-moskauGrotesk tw-font-normal">
                    {t('CLIMATE_ACTIONS.NO_ALIGNEMENT')}
                  </div>
                  <div className="tw-text-xs tw-text-notePasDengagement tw-font-moskauGrotesk tw-font-semibold tw-mt-1">
                    {t('CLIMATE_ACTIONS.NO_COMMITMENT_2')}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {isDesktop ? (
        <div className="tw-w-full tw-flex tw-items-center tw-py-3 tw-border tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-greySix">
          <div className="tw-w-2/5 tw-flex tw-items-center tw-gap-x-2">
            <div key={scope3Color} className={`tw-w-4 tw-h-4 tw-min-h-4 tw-min-w-4 tw-self-center tw-bg-${climateObjectivesData?.obj_scope_3_n_1?.percent_reduction_target ? getClimateAlignementInfos(climateObjectivesData?.obj_scope_3_n_1.manual_degree_position).bgColor : 'notePasDengagement'} tw-rounded-full`} style={{ border: `4px solid ${getHexColorWithOpacity(scope3Color, 0.1)}`, backgroundClip: 'padding-box' }} />
            <div className=" tw-text-base tw-text-primaryNewBlue tw-font-moskauGrotesk tw-font-semibold">
              {t('CLIMATE_ACTIONS.SCOPE_3')}
            </div>
          </div>
          <div className="tw-w-3/5 tw-flex tw-items-center">
            <div className="tw-w-1/2 tw-flex tw-flex-col tw-items-center">
              {climateObjectivesData?.obj_scope_3_n_1
                ?.percent_reduction_target ? (
                  <>
                    <div className=" tw-font-moskauGrotesk tw-font-semibold tw-text-lg">
                      {
                      climateObjectivesData?.obj_scope_3_n_1
                        ?.percent_reduction_target
                    }
                      %
                    </div>
                    <div className="tw-text-xs tw-text-greyTwo">
                      {t(unitText[climateObjectivesData?.obj_scope_3_n_1?.direct_value_type])}
                    </div>
                    <div className="tw-text-xs tw-text-greyTwo">
                      {t('CLIMATE_ACTIONS.BETWEEN')}{' '}
                      {
                      climateObjectivesData?.obj_scope_3_n_1
                        .complete_initial_year
                    }{' '}
                      et{' '}
                      {climateObjectivesData?.obj_scope_3_n_1.complete_final_year}
                    </div>
                  </>
                ) : (
                  ''
                )}
            </div>
            <div className="tw-w-1/2 tw-flex tw-flex-col tw-items-center">
              {climateObjectivesData?.obj_scope_3_n_1
                ?.percent_reduction_target ? (
                  <div
                    className={`tw-w-24 tw-text-sm tw-font-medium tw-text-${
                      getClimateAlignementInfos(
                        climateObjectivesData?.obj_scope_3_n_1
                          .manual_degree_position,
                      ).textColor
                    } tw-text-center tw-py-1 tw-bg-${
                      getClimateAlignementInfos(
                        climateObjectivesData?.obj_scope_3_n_1
                          .manual_degree_position,
                      ).bgColor
                    }/10 tw-rounded-2xl tw-border-${
                      getClimateAlignementInfos(
                        climateObjectivesData?.obj_scope_3_n_1
                          .manual_degree_position,
                      ).textColor
                    } tw-border tw-border-solid`}
                  >
                    {
                    getClimateAlignementInfos(
                      climateObjectivesData?.obj_scope_3_n_1
                        .manual_degree_position,
                    ).text
                  }
                  </div>
                ) : (
                  <>
                    <div className="tw-text-xs tw-text-greyThree tw-font-moskauGrotesk tw-font-normal">
                      {t('CLIMATE_ACTIONS.NO_ALIGNEMENT')}
                    </div>
                    <div className="tw-text-xs tw-text-notePasDengagement tw-font-moskauGrotesk tw-font-semibold tw-mt-1">
                      {t('CLIMATE_ACTIONS.NO_COMMITMENT_2')}
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      ) : (
        <div className="tw-w-full tw-flex tw-flex-col tw-gap-y-6 tw-border tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-greySix tw-py-6">
          <div className="tw-w-full tw-flex tw-items-center tw-gap-x-2">
            <div key={scope3Color} className={`tw-w-4 tw-h-4 tw-min-h-4 tw-min-w-4 tw-self-center tw-bg-${climateObjectivesData?.obj_scope_3_n_1?.percent_reduction_target ? getClimateAlignementInfos(climateObjectivesData?.obj_scope_3_n_1.manual_degree_position).bgColor : 'notePasDengagement'} tw-rounded-full`} style={{ border: `4px solid ${getHexColorWithOpacity(scope3Color, 0.1)}`, backgroundClip: 'padding-box' }} />
            <div className=" tw-text-base tw-text-primaryNewBlue tw-font-moskauGrotesk tw-font-semibold">
              {t('CLIMATE_ACTIONS.SCOPE_3')}
            </div>
          </div>
          <div className="tw-w-full tw-flex tw-items-center">
            <div className="tw-w-1/2 tw-text-xs tw-text-greyFour tw-leading-[18px]">
              {t('CLIMATE_ACTIONS.TARGET_REDUCTION')}
            </div>
            <div className="tw-w-1/2 tw-flex tw-flex-col tw-items-center">
              {climateObjectivesData?.obj_scope_3_n_1
                ?.percent_reduction_target ? (
                  <>
                    <div className=" tw-font-moskauGrotesk tw-font-semibold tw-text-lg">
                      {
                      climateObjectivesData?.obj_scope_3_n_1
                        ?.percent_reduction_target
                    }
                      %
                    </div>
                    <div className="tw-text-xs tw-text-greyTwo">
                      {t(unitText[climateObjectivesData?.obj_scope_3_n_1?.direct_value_type])}
                    </div>
                    <div className="tw-text-xs tw-text-greyTwo">
                      {t('CLIMATE_ACTIONS.BETWEEN')}{' '}
                      {
                      climateObjectivesData?.obj_scope_3_n_1
                        .complete_initial_year
                    }{' '}
                      et{' '}
                      {climateObjectivesData?.obj_scope_3_n_1.complete_final_year}
                    </div>
                  </>
                ) : (
                  ''
                )}
            </div>
          </div>
          <div className="tw-w-full tw-flex tw-items-center">
            <div className="tw-w-1/2 tw-text-xs tw-text-greyFour tw-leading-[18px]">
              {t('CLIMATE_ACTIONS.CLIMATE_ALIGNEMENT')}
            </div>
            <div className="tw-w-1/2 tw-flex tw-flex-col tw-items-center">
              {climateObjectivesData?.obj_scope_3_n_1
                ?.percent_reduction_target ? (
                  <div
                    className={`tw-w-24 tw-text-sm tw-font-medium tw-text-${
                      getClimateAlignementInfos(
                        climateObjectivesData?.obj_scope_3_n_1
                          .manual_degree_position,
                      ).textColor
                    } tw-text-center tw-py-1 tw-bg-${
                      getClimateAlignementInfos(
                        climateObjectivesData?.obj_scope_3_n_1
                          .manual_degree_position,
                      ).bgColor
                    }/10 tw-rounded-2xl tw-border-${
                      getClimateAlignementInfos(
                        climateObjectivesData?.obj_scope_3_n_1
                          .manual_degree_position,
                      ).textColor
                    } tw-border tw-border-solid`}
                  >
                    {
                    getClimateAlignementInfos(
                      climateObjectivesData?.obj_scope_3_n_1
                        .manual_degree_position,
                    ).text
                  }
                  </div>
                ) : (
                  <>
                    <div className="tw-text-xs tw-text-greyThree tw-font-moskauGrotesk tw-font-normal">
                      {t('CLIMATE_ACTIONS.NO_ALIGNEMENT')}
                    </div>
                    <div className="tw-text-xs tw-text-notePasDengagement tw-font-moskauGrotesk tw-font-semibold tw-mt-1">
                      {t('CLIMATE_ACTIONS.NO_COMMITMENT_2')}
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      )}
      <a
        href={`https://www.openclimat.com/${getUserLocal()}/methodology`}
        className="tw-text-sm tw-font-normal tw-text-greyFour tw-mt-6"
        rel="noopener noreferrer"
      >
        {t('CLIMATE_ACTIONS.SBTI_METHODOLOGY')}
      </a>
    </div>
  );
}

export default ClimateCommitments;
